import React, { Fragment } from 'react'
import { StaticQuery, graphql } from 'gatsby'

import styled from 'styled-components'

import Back from '../../components/caseStudies/back'
import BrandBox from '../../components/caseStudies/brandBox'
import ContentWrapper from '../../components/share/contentWrapper'
import Details from '../../components/caseStudies/details'
import Header from '../../components/caseStudies/header'
import IntroRow from '../../components/share/introRow'
import Layout from '../../components/layout'
import SpeakToTeam from '../../components/share/speakToTeam'
import TypeFormButton from '../../components/share/typeFormButton'

import brandIcon from '../../images/brandLogos/ljHooker.svg'

const query = graphql`
  query {
    header: file(relativePath: { eq: "caseStudies/header-ljhooker.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    agentImage: file(
      relativePath: { eq: "brandAgentIcon/john_cunningham.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const LJHooker = props => {
  return (
    <Layout>
      <StaticQuery
        query={query}
        render={data => (
          <Fragment>
            <Header
              background={data.header.childImageSharp.fluid}
              year="1928"
              iconStyle={{ width: '125px' }}
              url="ljhooker.com.au"
              brandName="LJ Hooker Real Estate Group"
              icon={brandIcon}
              overlayColor="linear-gradient(130.91deg, rgba(227, 31, 31, 0.7) 17.21%, rgba(241, 203, 7, 0.7) 99.7%)"
              content={
                <Fragment>
                  Over 730 offices across 6 countries and more than 8000
                  employees with a very particular set of requirements from the
                  smallest offices up to brand level oversight of their
                  marketing.
                </Fragment>
              }
            />

            <ContentWrapper style={{ margin: '40px auto' }}>
              <Details
                sizes={['International', 'Franchise']}
                integrations={[
                  'CRMs',
                  'National Supplier Network (180+)',
                  'National Newspapers (85+)',
                ]}
                customTitle="# OF SALES IN 2018"
                customData={['60,000+']}
              />
            </ContentWrapper>

            <ContentWrapper style={{ margin: '40px auto' }}>
              <IntroRow
                descWidth="85%"
                title="A monumental change of over 730 offices nationwide for a monumental facelift of an existing brand."
                content={
                  <Fragment>
                    <Paragraph>
                      The real estate landscape has changed more in the last 10
                      years, than it has in the last hundred. One group LJ
                      Hooker has been around to witness all those changes first
                      hand. Better positioned than any other to analyse trends
                      and make informed decisions on how best to brace for the
                      future LJ Hooker decided to partner with Realhub across
                      their entire network.
                    </Paragraph>
                    <Paragraph>
                      The large undertaking of moving across an entire network
                      of offices from across the country was finalised from
                      decision to completion in under 3 months on a tight
                      deadline to coincide with their 90 years of innovation
                      celebration.
                    </Paragraph>
                    <Paragraph>
                      Across the country offices were moved on to new branding
                      and a whole new platform all at once. The system grew and
                      advanced to accommodate the varied requirements of such a
                      large group and saw hundreds of new suppliers across
                      print, newspaper and digital added into the platform
                      enabling hundreds of offices to transition smoothly into a
                      new way of operating their businesses.
                    </Paragraph>
                  </Fragment>
                }
              />
            </ContentWrapper>

            <ContentWrapper style={{ margin: '40px auto' }}>
              <IntroRow
                descWidth="75%"
                content={
                  <Fragment>
                    <Paragraph>
                      The team at LJ Hooker head office worked together with our
                      onboarding crew to ensure every single office had every
                      piece of marketing they required across a suite of
                      thousands and thousands of templates.
                    </Paragraph>
                    <Paragraph>
                      Accomodating for unique supplier specifications and an
                      industry first opportunity to provide offices with more
                      than one brand template suite for their office making the
                      concept of one brand, spanning every market size and
                      requirement a possibility for the first time ever.
                    </Paragraph>
                    <Paragraph>
                      Ongoing, Realhub is constantly and consistently updating
                      and creating new marketing possibilities the group has
                      never before been able to control.
                    </Paragraph>

                    <Paragraph>
                      Beat the disruption like LJ Hooker.{' '}
                      <TypeFormButton
                        text="Join the team at Realhub today."
                        plain
                      />
                    </Paragraph>
                  </Fragment>
                }
              />
            </ContentWrapper>

            <ContentWrapper>
              <SpeakToTeam
                uid="WpgxlK"
                avatar="https://labs-assets.typeform.com/cui/avatars/WpgxlK/cde0f362a9e74bff7bfc.png"
              />
            </ContentWrapper>

            <ContentWrapper style={{ margin: '40px auto' }}>
              <Back />
              <Flex>
                <BrandBox
                  brandName="BelleProperty"
                  iconStyle={{ height: '50px' }}
                />
                <BrandBox brandName="Harris" />
                <BrandBox brandName="BarryPlant" />
              </Flex>
            </ContentWrapper>
          </Fragment>
        )}
      />
    </Layout>
  )
}

export default LJHooker

const Paragraph = styled.p`
  font-family: Open Sans;
  margin-bottom: 30px;
`

const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
`
